
import { IonPage, IonContent, IonRow, IonGrid } from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Account",
  components: {
    IonPage,
    IonContent,
    IonRow,
    Header,
    Footer,
    IonGrid,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  mounted() {
    this.store.dispatch("checkAuth");
  },
});
